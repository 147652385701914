var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              attrs: {
                color: "primary",
                app: "",
                "mini-variant": _vm.mini,
                permanent: "",
                "expand-on-hover": ""
              },
              on: {
                "update:miniVariant": function($event) {
                  _vm.mini = $event
                },
                "update:mini-variant": function($event) {
                  _vm.mini = $event
                }
              },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { staticClass: "px-2" },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c("v-app-bar-nav-icon", {
                            staticClass: "white--text"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "v-list-item",
                    { key: item.title, attrs: { link: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v(_vm._s(item.icon))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "white--text text-caption" },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-app-bar",
            {
              staticClass: "white lighten-4 text-center",
              attrs: { app: "", dense: "", flat: "" }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    color: "light success--text rounded-lg",
                    elevation: "0"
                  }
                },
                [_vm._v(" Create Campaign ")]
              ),
              _c(
                "v-btn",
                { staticClass: "mx-6", attrs: { icon: "" } },
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      "max-height": "10",
                      "max-width": "14",
                      src: require("../assets/images/united-states.svg")
                    }
                  }),
                  _vm._v(" EN ")
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { bottom: "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { icon: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-chevron-down")])],
                            1
                          ),
                          _c(
                            "v-avatar",
                            { attrs: { color: "primary", size: "30" } },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text caption" },
                                [_vm._v("JD")]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.items, function(item, i) {
                      return _c(
                        "v-list-item",
                        { key: i },
                        [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inner-container" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "head-title light-bg d-flex justify-space-between mb-0 pa-9"
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "headline font-weight-medium primary--text"
                    },
                    [_vm._v("Keyword Auction Insights")]
                  ),
                  _c(
                    "div",
                    { staticClass: "right-head-col d-flex" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey--text datepicker-outlined",
                          attrs: { depressed: "", outlined: "", height: "40" }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu5",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.enddate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto"
                              },
                              on: {
                                "update:returnValue": function($event) {
                                  _vm.enddate = $event
                                },
                                "update:return-value": function($event) {
                                  _vm.enddate = $event
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                "append-icon": "mdi-calendar",
                                                readonly: ""
                                              },
                                              model: {
                                                value: _vm.enddate,
                                                callback: function($$v) {
                                                  _vm.enddate = $$v
                                                },
                                                expression: "enddate"
                                              }
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menu5,
                                callback: function($$v) {
                                  _vm.menu5 = $$v
                                },
                                expression: "menu5"
                              }
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.enddate,
                                    callback: function($$v) {
                                      _vm.enddate = $$v
                                    },
                                    expression: "enddate"
                                  }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.menu = false
                                        }
                                      }
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.menu5.save(
                                            _vm.enddate
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" OK ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-select", {
                        staticClass: "ml-2",
                        attrs: {
                          items: _vm.country,
                          label: "United States",
                          outlined: "",
                          dense: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      { attrs: { elevation: "0" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "custom-tab",
                            attrs: { "background-color": "transparent" },
                            on: {
                              change: function($event) {
                                return _vm.tabChanged(_vm.tab)
                              }
                            },
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.items, function(item) {
                            return _c("v-tab", { key: item.tab }, [
                              _vm._v(" " + _vm._s(item.tab) + " ")
                            ])
                          }),
                          1
                        ),
                        _c(
                          "v-tabs-items",
                          {
                            staticClass: "mt-15",
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.items, function(item) {
                            return _c(
                              "v-tab-item",
                              { key: item.id },
                              [
                                _c("v-card", { attrs: { flat: "" } }, [
                                  item.id == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "search-col" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "7" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "box-header d-flex justify-space-between align-center pa-3"
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        ref: "searchAppRef",
                                                        refInFor: true,
                                                        attrs: {
                                                          loading: _vm.loading,
                                                          items:
                                                            _vm.searchedApps,
                                                          "search-input":
                                                            _vm.search,
                                                          "cache-items": "",
                                                          outlined: "",
                                                          "hide-no-data": "",
                                                          "hide-details": "",
                                                          "item-text":
                                                            "appName",
                                                          "item-value":
                                                            "adamId",
                                                          label: "Search app"
                                                        },
                                                        on: {
                                                          "update:searchInput": function(
                                                            $event
                                                          ) {
                                                            _vm.search = $event
                                                          },
                                                          "update:search-input": function(
                                                            $event
                                                          ) {
                                                            _vm.search = $event
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.searchAppId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.searchAppId = $$v
                                                          },
                                                          expression:
                                                            "searchAppId"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "closeIcon pa-0",
                                                          attrs: {
                                                            "min-width": "50px",
                                                            "min-height": "50px"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.getKeysForApp
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "success--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-magnify"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "pa-5 d-flex ml-12",
                                                          attrs: {
                                                            color:
                                                              "grey lighten-4",
                                                            elevation: "0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "closeIcon pa-0",
                                                              attrs: {
                                                                text: "",
                                                                absolute: "",
                                                                top: "",
                                                                right: "",
                                                                "min-width":
                                                                  "20px"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeApp
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "success--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-img", {
                                                            staticClass:
                                                              "rounded-lg",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              "lazy-src":
                                                                _vm.appIcon,
                                                              "max-width": "60",
                                                              "max-height":
                                                                "60",
                                                              "min-width": "20",
                                                              "min-height":
                                                                "20",
                                                              src: _vm.appIcon
                                                            }
                                                          }),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pa-0 pl-8"
                                                            },
                                                            [
                                                              _c(
                                                                "v-card-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold pb-2 body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.appTitle
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-divider", {
                                                        staticClass:
                                                          "ml-13 mr-8",
                                                        attrs: { vertical: "" }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "5" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between align-center total-summary-content flex-wrap mt-7"
                                                    },
                                                    [
                                                      _c(
                                                        "v-sheet",
                                                        {
                                                          staticClass:
                                                            "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                          attrs: {
                                                            color: "white",
                                                            elevation: "0",
                                                            width: "100%",
                                                            "max-width":
                                                              "33.333%"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "top-sheet-col d-flex justify-center flex-column"
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pt-2 d-flex justify-space-between"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.appTPK
                                                                    ) + " "
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-5",
                                                                                        attrs: {
                                                                                          text:
                                                                                            "",
                                                                                          width:
                                                                                            "60"
                                                                                        }
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "success--text",
                                                                                        attrs: {
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-help-circle-outline"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Top tooltip"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Total Paid keywords"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "ml-8 mr-12",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-sheet",
                                                        {
                                                          staticClass:
                                                            "keyword-detail-right justify-space-between d-flex align-center font-weight-medium text-h5",
                                                          attrs: {
                                                            color: "white",
                                                            elevation: "0",
                                                            width: "100%",
                                                            "max-width":
                                                              "33.333%"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "top-sheet-col d-flex justify-center flex-column"
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 pt-2 d-flex justify-space-between"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.appAC
                                                                    ) + " "
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-5",
                                                                                        attrs: {
                                                                                          text:
                                                                                            "",
                                                                                          width:
                                                                                            "60"
                                                                                        }
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "success--text",
                                                                                        attrs: {
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-help-circle-outline"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Top tooltip"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Acitve Countries"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "ml-8 mr-12",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }